import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WalletOverview.module.sass";
import Wallet from "../../components/Wallet";
import Icon from "../../components/Icon";
import AssetBalances from "./AssetBalances";
import { useDispatch, useSelector } from "react-redux";
import { getDigitsAfterDecimal } from "../../components/helper";
import { userStatusCreator } from "../../actions/getUserStatus";
import { getCoinsCreator } from "../../actions/getCoins";

// const navigation = ["Spot", "Cross", "Isolated", "Earn"];
const navigation = ["Spot", "Earn"];

const WalletOverview = () => {
  const [search, setSearch] = useState("");
  const [hideZero, setHideZero] = useState(1);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(navigation[0]);

  const { userStatus, walletData, loading } = useSelector((state) => { return state.getUserStatus });
  const { coinsData } = useSelector((state) => { return state.coins });
  const [visibleTransfer, setVisibleTransfer] = useState(false);

  useEffect(() => {
    // if (userStatus.length === 0) {
    dispatch(userStatusCreator());
    // }

    if (coinsData.length === 0) {
      dispatch(getCoinsCreator());
    };
  }, []);

  const btnZeroHandler = (val) => {
    setHideZero(val);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Wallet userStatus={userStatus}>
      <div className={styles.top}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(`${styles.link} button-stroke`, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => handleTabClick(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.top}>
        <div className={styles.line}>
          <h4 className={cn("h4", styles.title)}>Wallet Overview</h4>
        </div>
        <div className={styles.self}>
          <div className={styles.details}>
            <div className={styles.info}>Total balance in USDT</div>
            <div className={styles.currency}>
              {/* <div className={styles.number}>{walletData?.total_usdt ? getDigitsAfterDecimal(walletData?.total_usdt, walletData?.usdt_decimal_price_precision) : "0.00"}</div> */}
              <div className={styles.number}>
                {
                  activeTab?.toLowerCase() === "spot" ?
                    userStatus?.wallet?.total_usdt ? getDigitsAfterDecimal(userStatus?.wallet?.total_usdt, 3) : "0.00"
                    :
                    activeTab?.toLowerCase() === "cross" ?
                      userStatus?.cross_margin_wallet ? getDigitsAfterDecimal(userStatus?.cross_margin_wallet?.total_usdt, 3) : "0.00"
                      :
                      activeTab?.toLowerCase() === "isolated" ?
                        userStatus?.isolated_margin_wallet ? getDigitsAfterDecimal(userStatus?.isolated_margin_wallet?.total_usdt, 3) : "0.00"
                        :
                        userStatus?.stake_wallet ? getDigitsAfterDecimal(userStatus?.stake_wallet?.total_usdt, 3) : "0.00"
                }
              </div>
              <div className={cn("category-green", styles.category)}>USDT</div>
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.info}>Total balance in BTC</div>
            <div className={styles.currency}>
              {/* <div className={styles.number}>{walletData?.total_btc ? getDigitsAfterDecimal(walletData?.total_btc, walletData?.btc_decimal_amount_precision) : "0.0000"}</div> */}
              <div className={styles.number}>
                {

                  activeTab?.toLowerCase() === "spot" ?
                    userStatus?.wallet ? getDigitsAfterDecimal(userStatus?.wallet?.total_btc, 8) : "0.0000"
                    :
                    activeTab?.toLowerCase() === "cross" ?
                      userStatus?.cross_margin_wallet?.total_btc ? getDigitsAfterDecimal(userStatus?.cross_margin_wallet?.total_btc, 8) : "0.0000"
                      :
                      activeTab?.toLowerCase() === "isolated" ?
                        userStatus?.isolated_margin_wallet ? getDigitsAfterDecimal(userStatus?.isolated_margin_wallet?.total_btc, 8) : "0.0000"
                        :
                        userStatus?.stake_wallet ? getDigitsAfterDecimal(userStatus?.stake_wallet?.total_btc, 8) : "0.0000"
                }</div>
              <div className={cn("category-green", styles.category)}>BTC</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.head}></div>
          <div className={styles.body}>
            <div className={styles.zeroBalanceButton}>
              <div className={styles.search}>
                <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                  <input
                    className={styles.input}
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    placeholder="Search wallet by name"
                    required
                  />
                  <button className={styles.result}>
                    <Icon name="search" size="20" />
                  </button>
                </form>
              </div>

              <div className={styles.hideZeroAndTransferButtons}>
                {hideZero === 0 ? (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => btnZeroHandler(1)}
                  >
                    Show Zero Balances
                  </button>
                ) : (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => btnZeroHandler(0)}
                  >
                    Hide Zero Balances
                  </button>
                )}
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibleTransfer(true);
                  }}
                >Transfer</button>
              </div>
            </div>

            <AssetBalances
              coinsData={coinsData}
              userStatus={userStatus}
              hideZero={hideZero}
              search={search}
              walletData={userStatus?.wallet}
              loading={loading}
              activeTab={activeTab}
              visibleTransfer={visibleTransfer}
              setVisibleTransfer={setVisibleTransfer}
            />
          </div>
        </div>
      </div>
    </Wallet>
  );
};

export default WalletOverview;
