import React from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Item from "./Item";

const Funds = ({
  leverageData,
}) => {

  return (
    <div className={cn("container", styles.newContainer)}>
      <div className={styles.wrap}>
        <h1 className={cn("h2", styles.title)}>
          Margin Position Tiers
        </h1>
        <div className={styles.list}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Position Bracket (Liability value in USDT)</div>
            <div className={styles.col}>Max Leverage(X)</div>
            <div className={styles.col}>Maintenance Margin Rate(%)</div>
            <div className={styles.col}>Hourly Interest Rate(%)</div>
            <div className={styles.col}>Maintenance Amount(USDT)</div>
          </div>
          {leverageData?.map((x, index) => (
            <Item
              className={styles.item}
              item={x}
              key={index}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Funds;
