import React from "react";
import cn from "classnames";
import styles from "./History.module.sass";
import { getDigitsAfterDecimal } from "../helper";

const History = ({ item, orders, coin, currency, decimalPrecison, amountPrecision, active }) => {

  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className="">#</div>
        </div>
        <div className={styles.col}>
          <div className="">Match ID</div>
        </div>
        <div className={styles.col}>
          <div className="">Amount</div>
        </div>
        <div className={styles.col}>
          <div className="">Value</div>
        </div>
        {!active && (
          <>
            <div className={styles.col}>
              <div className="">Rate</div>
            </div>
            <div className={styles.col}>
              <div className="">Fee </div>
            </div>
          </>
        )}
      </div>

      {orders.map((order, x) => {
        return (
          <div className={styles.row} key={x}>
            <div className={styles.col}>{x + 1}</div>
            <div className={styles.col}>{order.match_orderId}</div>
            <div className={styles.col}>
              <div className={styles.info}> {getDigitsAfterDecimal(order.exeCrypto, amountPrecision)}   <span className={styles.conv}> {coin.toUpperCase()} </span></div>
            </div>
            <div className={styles.col}> {getDigitsAfterDecimal(order.exeFiat, decimalPrecison)} <span className={styles.conv}> {currency.toUpperCase()} </span></div>
            {/* {!active && (
              <>
                <div className={styles.col}> {getDigitsAfterDecimal(order.fiatPrice, decimalPrecison)} <span className={styles.conv}> {currency.toUpperCase()} </span></div>
                <div className={styles.col}>
                  <div className={styles.info}>{getDigitsAfterDecimal(((0.2 / 100) * order.exeFiat), decimalPrecison, true)} <span className={styles.conv}> {currency.toUpperCase()} </span></div>
                  {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
                    <>
                      {getDigitsAfterDecimal(order?.fiatFee, item?.priceDecimalPrecision)} <span className={styles.conv}> {currency.toUpperCase()} </span>
                    </>
                    :
                    (item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                      <>
                        {getDigitsAfterDecimal(order?.cryptoFee, item?.amountDecimalPrecision)} <span className={styles.conv}> {coin.toUpperCase()} </span>
                      </>
                      :
                      null}
                </div>
              </>
            )} */}

            {!active && (
              <>
                {/* <div className={styles.col}> {getDigitsAfterDecimal(order.fiatPrice, decimalPrecison)} <span className={styles.conv}> {currency.toUpperCase()} </span></div> */}
                <div className={styles.col}>Market</div>
                <div className={styles.col}>
                  {/* <div className={styles.info}>{getDigitsAfterDecimal(((0.2 / 100) * order.exeFiat), decimalPrecison, true)} <span className={styles.conv}> {currency.toUpperCase()} </span></div> */}
                  {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER_CLOSE_CROSS) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER_CLOSE_ISOLATED)) ?
                    <>
                      {/* {getDigitsAfterDecimal(order?.fiatFee, item?.priceDecimalPrecision)} <span className={styles.conv}> {currency.toUpperCase()} </span> */}
                      {/* <div className={styles.info}>{getDigitsAfterDecimal(((0.2 / 100) * order.exeFiat), decimalPrecison, true)} <span className={styles.conv}> {currency.toUpperCase()} </span></div> */}
                      <div className={styles.info}>{getDigitsAfterDecimal((order.exeFiat / (1 - 0.002)) - order.exeFiat, decimalPrecison)} <span className={styles.conv}> {currency.toUpperCase()} </span></div>
                    </>
                    :
                    (item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER_CLOSE_CROSS) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER_CLOSE_ISOLATED)) ?
                      <>
                        {/* {getDigitsAfterDecimal(order?.cryptoFee, item?.amountDecimalPrecision)} <span className={styles.conv}> {coin.toUpperCase()} </span> */}
                        {/* <div className={styles.info}>{getDigitsAfterDecimal(((0.2 / 100) * order.exeCrypto), amountPrecision)} <span className={styles.conv}> {coin.toUpperCase()} </span></div> */}
                        <div className={styles.info}>{getDigitsAfterDecimal((order.exeCrypto / (1 - 0.002)) - order.exeCrypto, amountPrecision)} <span className={styles.conv}> {coin.toUpperCase()} </span></div>
                      </>
                      :
                      null}
                </div>
              </>
            )}

          </div>
        )
      })}
    </div>
  )
};

export default History;
