import React from "react";
import styles from "./Form.module.sass";
import Action from "./Action";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../../components/Icon";

const Form = ({
  contentBuy,
  contentSell,
  price,
  stop,
  limit,
  visible,
  setValue,
  currentPrice,
  slug,
  marketId,
  coin,
  currency,
  orderResponse,
  tradingType,
  currentMarketDetails,
  activeTab,
  currencyAmount,
  coinAmount,
  setOrderFlag,
  orderFlag,
  exchangeData,
  createOrdersData,
  activeIndexAction,
  leverage,
  currentMarketPrice,
  crossMarginLeverage,
  isolatedMarginLeverage,
  leverageState,
  setMarketOrderType,
  crossMarginSellLeverage,
  isolatedMarginSellLeverage,
}) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={styles.form}>
      {isTablet ? (
        <>
          {visible ? (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={coin ? `Buy ${coin?.toUpperCase()}` : 'Buy'}
                content={contentBuy + ' ' + currency?.toUpperCase()}
                price={price}
                stop={stop}
                limit={limit}
                classButton="button-green"
                buttonText={`Buy ${coin.toUpperCase()}`}
                orderType="buy"
                currentPrice={currentPrice}
                slug={slug}
                marketId={marketId}
                coin={coin?.toUpperCase()}
                currency={currency?.toUpperCase()}
                buyBalance={contentBuy}
                sellBalance={contentSell}
                orderResponse={orderResponse}
                tradingType={tradingType}
                currentMarketDetails={currentMarketDetails}
                activeTab={activeTab}
                currencyAmount={currencyAmount}
                coinAmount={coinAmount}
                setOrderFlag={setOrderFlag}
                orderFlag={orderFlag}
                exchangeData={exchangeData?.data?.data}
                createOrdersData={createOrdersData}
                activeIndexAction={activeIndexAction}
                leverage={leverage}
                currentMarketPrice={currentMarketPrice}
                crossMarginLeverage={crossMarginLeverage}
                isolatedMarginLeverage={isolatedMarginLeverage}
                leverageState={leverageState}
                setMarketOrderType={setMarketOrderType}
                crossMarginSellLeverage={crossMarginSellLeverage}
                isolatedMarginSellLeverage={isolatedMarginSellLeverage}
              />
            </>
          ) : (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={coin ? `Sell ${coin?.toUpperCase()}` : 'Sell'}
                content={contentSell + ' ' + coin?.toUpperCase()}
                buyBalance={contentBuy}
                sellBalance={contentSell}
                price={price}
                stop={stop}
                limit={limit}
                classButton="button-red"
                buttonText={`Sell ${coin?.toUpperCase()}`}
                orderType="sell"
                currentPrice={currentPrice}
                slug={slug}
                marketId={marketId}
                coin={coin?.toUpperCase()}
                currency={currency?.toUpperCase()}
                orderResponse={orderResponse}
                tradingType={tradingType}
                currentMarketDetails={currentMarketDetails}
                activeTab={activeTab}
                currencyAmount={currencyAmount}
                coinAmount={coinAmount}
                setOrderFlag={setOrderFlag}
                orderFlag={orderFlag}
                exchangeData={exchangeData?.data?.data}
                createOrdersData={createOrdersData}
                activeIndexAction={activeIndexAction}
                leverage={leverage}
                currentMarketPrice={currentMarketPrice}
                crossMarginLeverage={crossMarginLeverage}
                isolatedMarginLeverage={isolatedMarginLeverage}
                leverageState={leverageState}
                setMarketOrderType={setMarketOrderType}
                crossMarginSellLeverage={crossMarginSellLeverage}
                isolatedMarginSellLeverage={isolatedMarginSellLeverage}
              />
            </>
          )}
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Action
              title={coin ? `Buy ${coin?.toUpperCase()}` : 'Buy'}
              content={contentBuy + ' ' + currency?.toUpperCase()}
              price={price}
              stop={stop}
              limit={limit}
              classButton="button-green"
              buttonText={`Buy ${coin?.toUpperCase()}`}
              orderType="buy"
              currentPrice={currentPrice}
              slug={slug}
              marketId={marketId}
              coin={coin?.toUpperCase()}
              currency={currency?.toUpperCase()}
              buyBalance={contentBuy}
              sellBalance={contentSell}
              orderResponse={orderResponse}
              tradingType={tradingType}
              currentMarketDetails={currentMarketDetails}
              activeTab={activeTab}
              currencyAmount={currencyAmount}
              coinAmount={coinAmount}
              setOrderFlag={setOrderFlag}
              orderFlag={orderFlag}
              exchangeData={exchangeData?.data?.data}
              createOrdersData={createOrdersData}
              activeIndexAction={activeIndexAction}
              leverage={leverage}
              currentMarketPrice={currentMarketPrice}
              crossMarginLeverage={crossMarginLeverage}
              isolatedMarginLeverage={isolatedMarginLeverage}
              leverageState={leverageState}
              setMarketOrderType={setMarketOrderType}
              crossMarginSellLeverage={crossMarginSellLeverage}
              isolatedMarginSellLeverage={isolatedMarginSellLeverage}
            />
          </div>
          <div className={styles.col}>
            <Action
              title={coin ? `Sell ${coin?.toUpperCase()}` : 'Sell'}
              content={contentSell + ' ' + coin?.toUpperCase()}
              price={price}
              stop={stop}
              limit={limit}
              classButton="button-red"
              buttonText={`Sell ${coin?.toUpperCase()}`}
              orderType="sell"
              currentPrice={currentPrice}
              slug={slug}
              marketId={marketId}
              coin={coin?.toUpperCase()}
              currency={currency?.toUpperCase()}
              buyBalance={contentBuy}
              sellBalance={contentSell}
              orderResponse={orderResponse}
              tradingType={tradingType}
              currentMarketDetails={currentMarketDetails}
              activeTab={activeTab}
              currencyAmount={currencyAmount}
              coinAmount={coinAmount}
              setOrderFlag={setOrderFlag}
              orderFlag={orderFlag}
              exchangeData={exchangeData?.data?.data}
              createOrdersData={createOrdersData}
              activeIndexAction={activeIndexAction}
              leverage={leverage}
              currentMarketPrice={currentMarketPrice}
              crossMarginLeverage={crossMarginLeverage}
              isolatedMarginLeverage={isolatedMarginLeverage}
              leverageState={leverageState}
              setMarketOrderType={setMarketOrderType}
              crossMarginSellLeverage={crossMarginSellLeverage}
              isolatedMarginSellLeverage={isolatedMarginSellLeverage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
