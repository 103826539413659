import { useEffect, useRef, useState } from "react";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";
import styles from "./Transfer.module.sass";
import cn from "classnames";
import Icon from "../Icon";
import CustomDropdown from "../CustomDropdown";
import requestHandler from "../../actions/httpClient";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../LoaderScreen";
import { addNotification } from "../Notification";
import { getDigitsAfterDecimal } from "../helper";
import { useDispatch } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";

let fromValues = ["Spot Wallet", "Earn Wallet"];
let toValues = ["Spot Wallet", "Earn Wallet"];

const MarginTradingTransfer = ({
  setVisibleFundTransfer
}) => {
  const [fromValue, setFromValue] = useState("Please Select a Value");
  const [toValue, setToValue] = useState("Please Select a Value");
  const [coinValue, setCoinValue] = useState();
  const [coinText, setCoinText] = useState("Please Select a Value");
  const [loading, setLoading] = useState(false);
  const [coinData, setCoinData] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [amount, setAmount] = useState();
  const [userBal, setUserBal] = useState("");
  const dispatch = useDispatch();
  const [filteredToValues, setFilteredToValues] = useState(toValues);
  const [decimalPrecision, setDecimalPrecision] = useState();

  useEffect(() => {
    const newFilteredToValues = toValues?.filter(value => value !== fromValue);
    setFilteredToValues(newFilteredToValues);
    if (!newFilteredToValues?.includes(toValue)) {
      setToValue("Please Select a Value");
    }
    getUsersCoinHandler();
  }, [fromValue, toValue]);

  const getUsersCoinHandler = async () => {
    setUserBal("");
    setCoinText("Please Select a Value");
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const userStatus = await requestHandler('getCustomerVerifictionStatus', 'post', data, 'jwt_token')
      setLoading(false);
      if (fromValue == "Spot Wallet") {
        const newArray = userStatus?.data?.data?.wallet?.balances?.filter((x) => x.symbol.toLowerCase() === "bdnx").map((x) => {
          return { "cId": x?.coinId, "cValue": `${x.name.toUpperCase()}(${x.symbol.toUpperCase()})`, "cGatewayId": x.balance, "cDecimalPrecision": x.decimalPrecision }
        });
        setCoinData([...newArray]);
      }
      else {
        const newArray = userStatus?.data?.data?.stake_wallet?.balances?.filter((x) => x?.symbol?.toLowerCase() === "bdnx").map((x) => {
          return { "cId": x?.coinId, "cValue": `${x.name.toUpperCase()}(${x.symbol.toUpperCase()})`, "cGatewayId": x.balance, "cDecimalPrecision": x.decimalPrecision }
        });
        setCoinData([...newArray]);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const transferWalletHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      coin_id: coinValue,
      amount: amount,
      from_wallet: fromValue === "Spot Wallet" ?
        process.env.REACT_APP_SPOT_WALLET
        :
        process.env.REACT_APP_EARN_WALLET,
      to_wallet: toValue === "Earn Wallet" ?
        process.env.REACT_APP_EARN_WALLET
        :
        process.env.REACT_APP_SPOT_WALLET,
    };
    try {
      const transferWalletPayload = await requestHandler("transfer_between_wallets", "post", data, "jwt_token");
      setLoading(false);
      if (transferWalletPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: transferWalletPayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleFundTransfer(false);
        dispatch(userStatusCreator());
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const onClickHandler = () => {
    if (simpleValidator.current.allValid()) {
      if (fromValue.toLowerCase() !== "please select a value" && toValue.toLowerCase() !== "please select a value") {
        // if (coinText.toLowerCase() !== "please select a value") {
        //   if (fromValue.toLowerCase() !== toValue.toLowerCase()) {
        //     if (parseFloat(amount) > parseFloat(userBal)) {
        //       addNotification({
        //         title: "Alert",
        //         message: "You do not have that much of amount available in your wallet.",
        //         type: "danger",
        //       });
        //     }
        //     else {
        //       transferWalletHandler();
        //     }
        //   }
        //   else {
        //     addNotification({
        //       title: "Alert",
        //       message: "You can not select same values in from wallet and to wallet.",
        //       type: "danger",
        //     });
        //   }
        // }
        // else {
        //   addNotification({
        //     title: "Alert",
        //     message: "Please select a coin.",
        //     type: "danger",
        //   });
        // }
        if (coinText.toLowerCase() !== "please select a value") {
          if (parseFloat(amount) > parseFloat(userBal)) {
            addNotification({
              title: "Alert",
              message: "You do not have that much of amount available in your wallet.",
              type: "danger",
            });
          }
          else {
            if (parseFloat(amount) === 0) {
              addNotification({
                title: "Alert",
                message: "Amount must be greater than 0.",
                type: "danger",
              });
            }
            else {
              transferWalletHandler();
            }
          }
        }
        else {
          addNotification({
            title: "Alert",
            message: "Please select a coin.",
            type: "danger",
          });
        }
      }
      else {
        addNotification({
          title: "Alert",
          message: "Please select a value",
          type: "danger",
        });
      }
    }
    else {
      simpleValidator.current.showMessages();
      setForceUpdate(1);
    }
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.transfer}>
        <div className={cn("h4", styles.title)}>
          Transfer BDNX
        </div>
        <div className={styles.wrap}>
          <div className={styles.category}>
            Available <br></br>balance
          </div>
          <div className={styles.details}>
            <div className={styles.currency}>{userBal ? getDigitsAfterDecimal(userBal, decimalPrecision) : "0.00"}</div>
            <div className={styles.price}>{coinText === "Please Select a Value" ? "---" : coinText?.split("(")[0]?.toUpperCase()}</div>
          </div>
        </div>
        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown}
            label="From Wallet"
            value={fromValue}
            setValue={setFromValue}
            options={fromValues}
          />
        </div>
        <div className={styles.sign}>
          <Icon name="arrows" size="16" />
        </div>
        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown}
            label="To Wallet"
            value={toValue}
            setValue={setToValue}
            options={filteredToValues}
          />
        </div>
        <div className={styles.field}>
          <CustomDropdown
            className={styles.dropdown}
            label="Select Crypto"
            value={coinValue}
            setValue={setCoinValue}
            text={coinText}
            setText={setCoinText}
            options={coinData}
            flag={true}
            setCGatewayId={setUserBal}
            setDecimalPrecision={setDecimalPrecision}
          />
        </div>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Amount"
            name="amount"
            type="text"
            placeholder="Please enter amount"
            required
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value == "" || re.test(e.target.value)) {
                setAmount(e.target.value)
              }
            }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("amount");
            }}
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("amount", amount, "required")}
        </span>
        <button className={cn("button", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            onClickHandler();
          }}>Transfer</button>
      </div>
    </>

  );
};

export default MarginTradingTransfer;
