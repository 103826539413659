import React from 'react';
import styles from './MarketScroller.module.sass';
import { getDigitsAfterDecimal } from "../helper";

const MarketScroller = ({ markets }) => {
    const duplicatedMarkets = [...markets, ...markets];

    return (
        <div className={styles.footercontainer}>
            <div className={styles.priceticker}>
                <div className={styles.marquee}>
                    {
                        duplicatedMarkets?.map((x, index) => {
                            return (
                                <div className={styles.tickeritem} key={index}>
                                    <span className={styles.symbol}>{x.slug.replace("-", "")}</span>
                                    <span
                                        className={`
                                            ${styles.changepositive} 
                                            ${x.dayChange < 0 ? styles.changeNegative : ''} 
                                        `}
                                    >
                                        {x.dayChange >= 0 ? `+${getDigitsAfterDecimal(x.dayChange, 2)}%` : `${getDigitsAfterDecimal(x.dayChange, 2)}%`}
                                    </span>
                                    <span className={styles.lastprice}>
                                        {x.currentMarketPrice}
                                    </span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default MarketScroller;
