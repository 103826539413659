import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { getDigitsAfterDecimal } from "../../../components/helper";
import { addNotification } from "../../../components/Notification";

const Successfully = ({
  toValue,
  fromValue,
  coinData,
  convertCoinHandler
}) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Confirm?
        </div>
        <div className={styles.exchangeinfo}>
          <div className={styles.currency}>
            <img src={`${process.env.REACT_APP_IMAGE_URL}/${coinData?.usdtBalance?.image}`} alt="usdt" />
            <span>From</span>
            <h3>{fromValue} USDT</h3>
          </div>
          <div className={styles.arrow}>→</div>
          <div className={styles.currency}>
            <img src={`${process.env.REACT_APP_IMAGE_URL}/${coinData?.bdnxBalance?.image}`} alt="usdt" />
            <span>To</span>
            <h3>{fromValue > 0 ? getDigitsAfterDecimal(parseFloat(fromValue) / parseFloat(toValue?.currentMarketPrice), toValue?.amountDecimalPrecision) : 0} BDNX</h3>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detail}>
            <span>Rate</span>
            <span>1 USDT ≈ {getDigitsAfterDecimal(1 / toValue?.currentMarketPrice, toValue?.amountDecimalPrecision)} {toValue?.symbol}</span>
          </div>
          <div className={styles.detail}>
            <span>Order Type</span>
            <span>Market</span>
          </div>
          <div className={styles.detail}>
            <span>Transaction Fees</span>
            <span>0 USDT</span>
          </div>
        </div>
        <div className={styles.info}>
          <button
            className={cn("button", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              if (parseFloat(fromValue) > parseFloat(coinData?.usdtBalance?.balance)) {
                addNotification({
                  title: "Alert",
                  message: "You don't have that much of amount available in your wallet.",
                  type: "danger",
                });
              }
              else {
                convertCoinHandler();
              }
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
