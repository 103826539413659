import React, { useState } from 'react';
import styles from "./Leverage.module.sass";
import cn from "classnames";

const LeverageTrading = ({
    setVisibleModal,
    setCrossMarginLeverage,
    setIsolatedMarginLeverage,
    leverageState,
    activeTab,
    marketOrderType,
    setCrossMarginSellLeverage,
    setIsolatedMarginSellLeverage,
}) => {
    const [selectedLeverage, setSelectedLeverage] = useState(null);
    const isConfirmButtonEnabled = selectedLeverage !== null;

    const getLeverageArray = () => {
        if (activeTab?.toLowerCase() === 'cross') {
            return marketOrderType?.toLowerCase() === 'buy'
                ? leverageState.currencyBalanceCrossMaxLeverage || []
                : leverageState.coinBalanceCrossMaxLeverage || [];
        } else if (activeTab?.toLowerCase() === 'isolated') {
            return marketOrderType?.toLowerCase() === 'buy'
                ? leverageState.currencyBalanceIsolatedMaxLeverage || []
                : leverageState.coinBalanceIsolatedMaxLeverage || [];
        }
        return [];
    };

    const handleLeverageChange = (value) => {
        setSelectedLeverage(value);
    };

    const handleConfirmClick = () => {
        if (isConfirmButtonEnabled) {
            if (activeTab?.toLowerCase() === 'cross') {
                if (marketOrderType?.toLowerCase() === 'buy') {
                    setCrossMarginLeverage(selectedLeverage);
                } else {
                    setCrossMarginSellLeverage(selectedLeverage);
                }
            } else if (activeTab?.toLowerCase() === 'isolated') {
                if (marketOrderType?.toLowerCase() === 'buy') {
                    setIsolatedMarginLeverage(selectedLeverage);
                } else {
                    setIsolatedMarginSellLeverage(selectedLeverage);
                }
            }
            setVisibleModal(false); // Close modal after confirmation
        }
    };

    return (
        <div className={styles.successfully}>
            <div className={styles.info}>
                {activeTab?.toLowerCase() === 'cross' ? 'Cross Margin Max Leverage' : 'Isolated Margin Max Leverage'}
            </div>
            <div className={styles.buttoncontainer}>
                {getLeverageArray()?.map((value) => (
                    <button
                        key={value}
                        className={cn(styles.buttonClass, {
                            [styles.active]: selectedLeverage === value
                        })}
                        onClick={() => handleLeverageChange(value)}
                    >
                        {value}x
                    </button>
                ))}
            </div>
            <div className={styles.btns}>
                <button
                    className={cn("button-small", styles.button, {
                        [styles.selected]: isConfirmButtonEnabled,
                        [styles.disabled]: !isConfirmButtonEnabled
                    })}
                    onClick={handleConfirmClick}
                    disabled={!isConfirmButtonEnabled}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default LeverageTrading;
