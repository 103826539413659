import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../components/Profile";
import SelectedQuestions from "./SelectQuestions";
import BankDetails from "./BankDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCoinsCreator } from "../../actions/getCoins";
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import EnterAddress from "./EnterAddress";
import { userStatusCreator } from "../../actions/getUserStatus";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Bank Verification",
  },
];

const BankVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [allData, setAllData] = useState({});
  const [address, setAddress] = useState({});
  const [bankList, setBankList] = useState([]);
  const [getBankVerificationQuestionsData, setGetBankVerificationQuestionsData] = useState([]);
  const { coinsData } = useSelector((state) => { return state.coins })
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const [gateWayList, setGateWayList] = useState([])
  const [loading, setLoading] = useState(false)
  const [primaryBankFlag, setPrimaryBankFlag] = useState(false);

  useEffect(() => {
    // if (userStatus.length == 0)
    //   dispatch(userStatusCreator())
    async function fetchData() {
      setLoading(true);

      // if (userStatus.kyc_verification && userStatus.kyc_verification == parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      //   dispatch(getCoinsCreator());
      //   try {
      //     const questionsPayload = await requestHandler("getBankWwftRegulation", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
      //     setLoading(false);
      //     setGetBankVerificationQuestionsData(questionsPayload?.data?.data);
      //   }
      //   catch (e) {
      //     setLoading(false);
      //   }
      // }

      if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
        dispatch(getCoinsCreator());
        try {
          const questionsPayload = await requestHandler("getBankWwftRegulation", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
          setLoading(false);
          setGetBankVerificationQuestionsData(questionsPayload?.data?.data);
        }
        catch (e) {
          setLoading(false);
        }
      }
      else {
        if (userStatus.kyc_verification && userStatus.kyc_verification == parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
          dispatch(getCoinsCreator());
          try {
            const questionsPayload = await requestHandler("getBankWwftRegulation", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
            setLoading(false);
            setGetBankVerificationQuestionsData(questionsPayload?.data?.data);
          }
          catch (e) {
            setLoading(false);
          }
        }
      }

    }
    fetchData();
  }, [userStatus])

  useEffect(() => {
    async function fetchDependentData() {
      if (userStatus.length == 0)
        dispatch(userStatusCreator())


      // if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      //   navigate("/user-kyc-verification");
      // }
      // // else if (userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS) || userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) || activeIndex === 2) {
      // else if ((userStatus.add_wwft_details === false) || activeIndex === 2) {
      //   setActiveIndex(2);
      //   setLoading(true)
      //   try {
      //     const bankListPayload = await requestHandler("getUserBankList", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
      //     setBankList(bankListPayload?.data?.data);
      //     const gatewayListPayload = await requestHandler("gateway_list", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
      //     setGateWayList(gatewayListPayload?.data?.data);
      //     setLoading(false)

      //   } catch (e) {
      //   };
      // }

      if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
        // else if (userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS) || userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) || activeIndex === 2) {
        if ((userStatus.add_wwft_details === false) || activeIndex === 2) {
          setActiveIndex(2);
          setLoading(true)
          try {
            const bankListPayload = await requestHandler("getUserBankList", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
            setBankList(bankListPayload?.data?.data);
            const gatewayListPayload = await requestHandler("gateway_list", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
            setGateWayList(gatewayListPayload?.data?.data);
            setLoading(false)

          } catch (e) {
          };
        }
      }
      else {
        if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
          navigate("/user-kyc-verification");
        }
        // else if (userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS) || userStatus.bank_verification && userStatus.bank_verification === parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) || activeIndex === 2) {
        else if ((userStatus.add_wwft_details === false) || activeIndex === 2) {
          setActiveIndex(2);
          setLoading(true)
          try {
            const bankListPayload = await requestHandler("getUserBankList", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
            setBankList(bankListPayload?.data?.data);
            const gatewayListPayload = await requestHandler("gateway_list", "post", { signature: localStorage.getItem("signature") }, "jwt_token");
            setGateWayList(gatewayListPayload?.data?.data);
            setLoading(false)

          } catch (e) {
          };
        }
      }

    }
    fetchDependentData();
  }, [activeIndex, userStatus, primaryBankFlag]);

  const goBack = () => {
    setActiveIndex(0);
  };

  const handleSubmit = () => {
    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      let userResident = {
        address: userStatus?.resultAddress?.address1,
        appartment: userStatus?.resultAddress?.address2,
        city: userStatus?.resultAddress?.city,
        state: userStatus?.resultAddress?.state,
        country: userStatus?.resultAddress?.country,
        zipCode: userStatus?.resultAddress?.zip_code,
        proof: userStatus?.resultAddress?.proof
      }
      setAddress(userResident);
      setActiveIndex(2);
    }
    else {
      setActiveIndex(activeIndex + 1);
    };
  };

  const questionsData = (fiat, account, coinText, volume, annualIncome, profession, fund,) => {
    let userData = {
      signature: localStorage.getItem("signature"),
      euro_from: fiat,
      why_open_account: account,
      most_trading_coin: coinText,
      avg_trading_annual_vol: volume,
      profession: profession,
      gross_income: annualIncome,
      transfer_outside_nth: fund,

    };
    setAllData(userData);
  };

  const residentData = (zipCode, address, appartment, country, state, city, addressProof) => {
    let userResident = {
      address: address,
      appartment: appartment,
      city: city,
      state: state,
      country: country,
      zipCode: zipCode,
      proof: addressProof
    }
    setAddress(userResident);
  }

  const bankDetailsData = async (paymentTypeValue, account, bankProof, PaymentMode, payment_mode_name, bankIssuer,) => {
    let bankData = {
      signature: localStorage.getItem("signature"),
      euro_from: allData.euro_from,
      why_open_account: allData.why_open_account,
      most_trading_coin: allData.most_trading_coin,
      avg_trading_annual_vol: allData.avg_trading_annual_vol,
      profession: allData.profession,
      gross_income: allData.gross_income,
      transfer_outside_nth: allData.transfer_outside_nth,
      bankProof: bankProof,
      account_number: account,
      payment_gateway_id: paymentTypeValue,
      payment_mode: PaymentMode,
      issuer: bankIssuer,
      address1: address.address,
      address2: address.appartment,
      city: address.city,
      state: address.state,
      country: address.country,
      zip_code: address.zipCode,
      proof: address.proof,
      payment_mode_name: payment_mode_name
    };
    try {
      setLoading(true)
      const verifyBankRequestPayload = await requestHandler("verify_bank_request", "post", bankData, "jwt_token", "file");
      setLoading(false)
      if (verifyBankRequestPayload && verifyBankRequestPayload.status === 200) {
        if (verifyBankRequestPayload?.data?.data?._links) {
          window.open(verifyBankRequestPayload?.data?.data?._links?.redirect?.href, "_self");
        } else {
          addNotification({
            title: 'Success',
            message: 'Request received',
            type: 'Success'
          });
          dispatch(userStatusCreator())
          navigate("/profile-info");
        }
      } else {
        addNotification({
          title: 'Error',
          message: verifyBankRequestPayload?.data?.message[0]?.msg,
          type: 'Danger'
        });
      }
      setLoading(false)
    }
    catch (e) {
      setLoading(false)
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'Danger'
      });
    };
  };

  const primaryBankHandler = async (accountNumber) => {
    setLoading(true);
    let data = {
      accountNumber: accountNumber,
      signature: localStorage.getItem("signature"),
    };
    try {
      const primaryBankPayload = await requestHandler("setPrimaryBankAccount", "post", data, "jwt_token");
      setLoading(false);
      if (primaryBankPayload?.status === 200) {
        setPrimaryBankFlag(!primaryBankFlag);
        // addNotification({
        //   title: "Success",
        //   message: primaryBankPayload?.data?.message[0]?.msg,
        //   type: "success",
        // });
      }
    }
    catch (e) {
      setLoading(false);
      // addNotification({
      //   title: "Error",
      //   message: e?.data?.message[0]?.msg,
      //   type: "danger",
      // });
    };
  };

  return (
    <Profile title="Bank Verification" breadcrumbs={breadcrumbs}>
      {activeIndex === 0 &&
        <SelectedQuestions
          getBankVerificationQuestionsData={getBankVerificationQuestionsData}
          coinsData={coinsData}
          handleSubmit={handleSubmit}
          questionsData={questionsData}
          loading={loading}
        />}

      {activeIndex === 1 &&
        <EnterAddress
          getBankVerificationQuestionsData={getBankVerificationQuestionsData}
          coinsData={coinsData}
          handleSubmit={handleSubmit}
          residentData={residentData}
        />}

      {activeIndex === 2 &&
        <BankDetails
          loading={loading}
          bankList={bankList}
          userStatus={userStatus}
          bankDetailsData={bankDetailsData}
          gateWayList={gateWayList}
          goBack={goBack}
          primaryBankHandler={primaryBankHandler}
        />}
    </Profile>
  );
};

export default BankVerification;
